import { IconMail, IconPhoneCall } from "@tabler/icons-react";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>AM</span> Rentals
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
              <li>
                <a href="tel:+9710506991162">
                  <IconPhoneCall /> &nbsp; (+971) 050-6991162
                </a>
              </li>
              <li>
                <a href="tel:+9710507212240">
                  <IconPhoneCall /> &nbsp; (+971) 050-7212240
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                  abmcar111@gmail.com"
                >
                  <IconMail />
                  &nbsp; abmcar111@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  // href="https://xpeedstudio.com/"
                >
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                {/* <a href="#home">United Arab Emirates</a> */}
                United Arab Emirates
              </li>
              <li>
                {/* <a href="#home">Careers</a> */}
                Al Falah St. (Old Passport Road)
              </li>
              <li>
                {/* <a href="#home">Mobile</a> */}
                Near PARCO Supermarket
              </li>
              <li>
                {/* <a href="#home">Mobile</a> */}
                PO Box: 25421 Abu Dhabi - UAE
              </li>
              <li>
                {/* <a href="#home">Blog</a> */}
                Tel: 02-6425577-Fax: 02-6425304
              </li>
              <li>
                <a
                  href="mailto: 
                  abmcar111@gmail.com"
                >
                  abmcar111@gmail.com
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Fri: 9:00AM - 9:00PM</li>
              <li>Sat: 9:00AM - 9:00PM</li>
              <li>Sun: Closed</li>
            </ul>

            <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <a
            style={{ fontSize: "14px" }}
            target="_blank"
            rel="noreferrer"
            // href="https://xpeedstudio.com/"
          >
            Design by InnovatEd
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
